import Box from 'UIKit/Box/Box'
import Link from 'UIKit/Link'
import { NextLinkFromReactRouter } from 'UIKit/NextLink'
import TransText from 'UIKit/Text/TransText'
import { RouteConfig } from 'config/constants/route'
import { ProviderInfo } from 'config/types'
import React, { useRef } from 'react'
import { useAppSelector } from 'state'
import { useResponsiveLayout } from 'state/app/hooks'
import { SpaceProps } from 'styled-system'
import { parselistToMatrix } from 'utils'
import { formatRoute } from 'utils/stringHelper'
import { Trans } from 'react-i18next'
import SectionTitle from './SectionTitle'
import { StyledContainerGameCollection, StyledGameProviderItem, StyledGridGame } from './styles'

const LAYOUT_CONFIG = {
  COL: [3, 4, 5, 5, 6, 8, 10],
  GAP: [4, 4, 6, 6, 10],
  ROW: 2,
}

const GameProvider: React.FC<SpaceProps> = ({ ...props }) => {
  const providers = useAppSelector((state) => state.app.providers)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const dataToMatrix = parselistToMatrix(providers, LAYOUT_CONFIG.ROW)

  const responsive = useResponsiveLayout()

  return (
    <Box {...props} overflow="hidden">
      <SectionTitle
        title={<Trans>Providers</Trans>}
        scrollRef={providers && containerRef}
        mb={['10px', '', '20px']}
        redirectButton={
          <Link href={RouteConfig.Providers}>
            <TransText color="#A8A9AC" fontSize={['12px', '12px', '16px']}>
              View all
            </TransText>
          </Link>
        }
      />
      <StyledContainerGameCollection
        col={responsive(LAYOUT_CONFIG.COL)}
        gap={LAYOUT_CONFIG.GAP}
        row={LAYOUT_CONFIG.ROW}
        itemCount={providers?.length}
      >
        <StyledGridGame ref={containerRef}>
          {dataToMatrix.length > 0 &&
            dataToMatrix.map((colProvider, index) => {
              return (
                <Box key={`row-grid-provider-${index}`}>
                  {colProvider.map((provider: ProviderInfo) => {
                    if (!provider) return
                    return (
                      <Box
                        key={`provider-${provider.code}`}
                        as={NextLinkFromReactRouter}
                        to={formatRoute(RouteConfig.ProviderDetail, {
                          code: provider.slug,
                        })}
                      >
                        <StyledGameProviderItem provider={provider} key={`item-provider-${provider.code}`} />
                      </Box>
                    )
                  })}
                </Box>
              )
            })}
        </StyledGridGame>
      </StyledContainerGameCollection>
    </Box>
  )
}

export default GameProvider
